require('slick-carousel');
require('./quickMobileScroll');
require('./components/ui-components/modal-bootstrap-v3.4.1');

const TooltipPositioning = require('./components/ui-components/tooltip.js').TooltipPositioning;
const simpleAccordion = require('./components/ui-components/simpleAccordion').simpleAccordion;
const lazyImages = require('./components/ui-components/LazyLoad.js').lazyImages;
const videoPopup = require('./components/ui-components/VideoPopup').videoPopup;

const {onModeEnter, onModeLeave, getMode} = require('./media.js');
const StickyHeader = require('./StickyHeader.js').StickyHeader;
const StickyTabs = require('./StickyHeader.js').StickyTabs;
const FooterAccordion = require('./FooterAccordion.js').FooterAccordion;
const Icons = require('./Icons.js').Icons;
const TemplatesSlider = require('./TemplatesSlider.js').TemplatesSlider;
const ReviewsSlider = require('./ReviewsSlider.js').ReviewsSlider;
const effects = require('./effects.js');

const debug = require('./debug.js');
const nailTab = require('./nail-salon-lending/tab.js');
const ReviewsSlider_v2 = require('./ReviewsSlider.js').ReviewsSlider_v2;
const support = require('./support.js');
const $ = require("jquery");
//const schedulingSoftwarePage = require('./scheduling-software/main.js'); // because displayed error on other pages !!!!!!

// we do not need it for a while
//global.Registration = require('./registration/registration').Registration;

global.Login = require('./login/login').Login;
global.Feedback = require('./feedback/feedback').Feedback;
global._t = _t;

//console.log('console.log in app.js')

new StickyHeader();
new StickyTabs();
new FooterAccordion();
new Icons();
new TemplatesSlider();
new ReviewsSlider();
new ReviewsSlider_v2();
new simpleAccordion();

// Handle lazy loading images
lazyImages();
// Handle lazy loading youtube video in popups
videoPopup();

support.supportChat();
new TooltipPositioning();

effects.addRippleToButton($('.btn, li.to-previous-step'));

debug.showScreenSize();

var menuWasOpen = false;

$('.header--togg-menu').click(function () {
    menuWasOpen = !menuWasOpen;
    closeMenu();
});

$('.morph-shape, .header--mob-togg-menu').click(function () {
    menuWasOpen = false;
    closeMenu();
});

// just for more comfortable view how many landings we have, REMOVE BEFORE PRODUCTION!!!!!
// commented by dimka, it is production time :) please uncomment if you need it.
/*function showReadyLandings() {
    let f_links = $('#footer ul li a');
    f_links.each(function (e) {
        if ($(this).attr('href') == '#') {
            $(this).css({'opacity': 0.4, 'cursor': 'not-allowed'});
        }
    });
}

showReadyLandings();*/


function closeMenu() {
    if (menuWasOpen) {
        $('body').addClass('menu--opened');
        //$('#header').addClass('header--stickly');
        $('.header--togg-menu, .header .morph-shape').addClass('open');

        // AnimateSvgOpenMobile(function () {
            $('.header--main-nav').addClass('open');
        // });

    } else {
        $('body').removeClass('menu--opened');
        //$('#header').removeClass('header--stickly');
        $('.header--main-nav').removeClass('open');
        $('.header--main-nav .menu--link-has-dropdown .submenu').removeClass('opened');
        // AnimateSvgCloseMobile(function () {
            $('.header--togg-menu, .morph-shape').removeClass('open');
        // });
    }
}


// var g_heightHeader, g_windowHeight, g_windowHeightThird, g_windowWidth;
// g_heightHeader = $('.header .header--settings-block .header--settings-container').height();
// g_windowHeight = $(window).height() - g_heightHeader;
// g_windowHeightThird = (g_windowHeight / 3);
// g_windowWidth = $(window).width();
//
$(window).resize(function () {
    // g_heightHeader = $('.header .header--settings-block .header--settings-container').height();
    // g_windowHeight = $(window).height() - g_heightHeader;
    // g_windowHeightThird = (g_windowHeight / 3);
    // g_windowWidth = $(window).width();
    //setHeightForCategoryItem();

    if ($(window).width() > 768) {
        menuWasOpen = false;
        closeMenu();
    }
});

function reviewAccordion() {
    $('.reviews--slider .read-more').click(function () {
        console.log('click');
        $(this).toggleClass('open');
        var txtBlock = $(this).closest('.short-review').toggleClass('full-review');
        $('.short-review').not($(this).closest('.short-review')).removeClass('full-review');


        if ( !$(txtBlock).hasClass("full-review") ) {
            txtBlock.animate({'max-height': 76});
        }
        else {
            //console.log('txtBlock.animate({\'max-height\': txtBlock[0].scrollHeight + "px"}')
            txtBlock.animate({'max-height': txtBlock[0].scrollHeight + "px"}, {
                step: function () {
                    $('.reviews--slider').slick('resize');
                    $('.reviews--slider').slick("setOption", null, null, true);
                }
            });
        }


        $('.reviews--slider .short-review').not($(this).closest('.short-review')).css('max-height', 76);
        $('.reviews--slider .read-more').not($(this)).removeClass('open');

        resizeReviewsCrousel();
        setTimeout(function () {
            resizeReviewsCrousel();
        }, 100)

       function resizeReviewsCrousel(){
           $('.reviews--slider').slick('resize');
           $('.reviews--slider').find(".slick-slide").height("auto");
           $('.reviews--slider').find(".slick-list.draggable").height("auto");
       }

        // Close current review description on next slider move
        $('.reviews--slider .slick-dots li:not(.slick-active) button').click(function () {
            //console.log('Close current review description on next slider move')
            txtBlock.animate({'max-height': 76});
            $('.reviews--slider .read-more').removeClass('open');
        });
    });
}

reviewAccordion();

function openSubMenuOnTuch() {
    $('.header--navigation-menu-container').on('click', '.header--main-nav-mobile .menu--link-has-dropdown', function () {
        var get_data = $(this).data("submenu");
        var sub_menu = $(this).closest('.header--main-nav-mobile .main-nav--menu').find('#' + get_data);
        $(sub_menu).addClass('opened');
    });
}

openSubMenuOnTuch();

function goToPrevMenuOnTuch() {
    $('.header--navigation-menu-container ').on('click', '.header--main-nav-mobile .submenu .to-previous-step', function (e) {
        $('.submenu').removeClass('opened');
        e.stopPropagation();
    })
}

goToPrevMenuOnTuch();

nailTab.tabNail($('.appointments-tabs-header .tab-switcher'));

nailTab.sbTAbs($('.tab-switcher--header .tab-switcher--header-item'));

function smoothScrollAnchorLinks() {
    $('.anchor-link').click(function (e) {
        e.preventDefault();
        $('.anchor-link').removeClass('active');
        //$(this).addClass('active');
        var target = $($(this).attr('href'));
        var offset_value = $(this).data('offset');

        if (getMode() != 'desktop') {
            offset_value = $(this).data('offset') - $(this).height();
        }
        if (getMode() == 'mob') {
            offset_value = 0;
        }

        $(window).resize(function () {
            if (getMode() != 'desktop') {
                offset_value = $(this).data('offset') - $(this).height();
            }
            if (getMode() == 'mob') {
                offset_value = 0;
            }
        });

        if (offset_value > 0) {
            console.log('has offset');
            if (target.length) {
                var scrollTo = target.offset().top - offset_value;
                $('body, html').stop().animate({scrollTop: scrollTo + 'px'}, 800);
            }
        } else {
            console.log('no offset');
            if (target.length) {
                var scrollTo = target.offset().top;
                $('body, html').stop().animate({scrollTop: scrollTo + 'px'}, 800);
            }
        }

    });
}

smoothScrollAnchorLinks();

function scrollToBlock(cur_block, callback, additionalOffset) {
    if ($(cur_block).length == 0) {
        return;
    }

    var isIn = checkIsIN();

    var additionalOffsetValue = additionalOffset;

    if (callback) callback(isIn);

    function checkIsIN() {
            var hT = cur_block.offset().top,
                hH = cur_block.outerHeight(),
                wH = $(window).height(),
                wS;

            if (additionalOffsetValue) {
                wS = $(window).scrollTop() + additionalOffsetValue;
            } else {
                wS = $(window).scrollTop();
            }


                return wS > (hT + hH - wH) && (hT > wS) && (wS + wH > hT + hH);
    }

    document.addEventListener('scroll', function () {
        if (checkIsIN()) {
            //console.log('in view');
            cur_block.addClass('in');
            if (!isIn) {
                if (callback) callback(true);
                isIn = true;
            }

        } else {
            //console.log('not in view');
            cur_block.removeClass('in');
            if (isIn) {
                if (callback) callback(false);
                isIn = false;
            }
        }
    }, {passive: true});
}

scrollToBlock($('#letter-block'));

scrollToBlock($('.worldwide--content-wrapper'), function (isIn) {
    var $mapPoints = $('.map-svg g path.st43');

    if (isIn) {
        $mapPoints.each(function (i) {
            var point = $(this);
            setTimeout(function () {
                point.removeClass('slideOutDown1').addClass('animated slideInDown1');
            }, i * 280);
        })

    } else {
        $mapPoints.each(function (i) {
            var point = $(this);
            setTimeout(function () {
                point.removeClass('slideInDown1').addClass('animated slideOutDown1');
            }, i * 280);
        })
    }
});

scrollToBlock($('.partnership__key-numbers_list'), function (isIn){
    if(isIn){
        numCounter();
    }
})

function numCounter(){
    $('.count-value').each(function () {
        let $this = $(this);
        let getDataVal = parseFloat($this.data('val'));
        let decimals = (getDataVal.toString().split('.')[1] || '').length;

        jQuery({ Counter: 0 }).animate({ Counter: getDataVal }, {
            duration: 2500,
            easing: 'swing',
            step: function () {
                $this.text(this.Counter.toFixed(decimals));
            }
        });
    });
}

// === integrations page === //
scrollToBlock($('#hero-section'), function (isIn) {
    var $smoothNav = $('.integrations__fixed-nav');

    if (isIn) {
        $smoothNav.addClass('is-invisible');
    } else {
        $smoothNav.removeClass('is-invisible');
    }
});

// ===== Scroll to Top ====
document.addEventListener('scroll', function () {
    if ($(window).scrollTop() >= 200) {
        $('#scroll-to-top').addClass('in');
    } else {
        $('#scroll-to-top').removeClass('in');
    }
}, {passive: true});

// $(window).scroll(function () {
//     if ($(window).scrollTop() >= 200) {
//         $('#scroll-to-top').addClass('in');
//     } else {
//         $('#scroll-to-top').removeClass('in');
//     }
// });

$('#scroll-to-top').click(function (e) {
    e.preventDefault();
    $('body,html').animate({
        scrollTop: 0
    }, 500);
});

// Accept payments management carousel
var $carousel = $('.ap-management__carousel');
var $line = $carousel.find('.ap-management__line');

if ($carousel.length > 0) {
    $line.each(function (index, el) {
        $(this).clone().addClass('cloned').insertAfter(this);
    });
}

function _t(key, options) {
    let debugLang = false;
    let str = key;
    if (!(typeof LANG != 'undefined') && LANG[key]) {
        str = LANG[key];
    } else if (debugLang) {
        console.warn('Lang key missing', key);
    }

    if (options !== undefined) {
        for (let k in options) {
            if (options.hasOwnProperty(k)) {
                let v = options[k];
                str = (str + '').replace('%' + k + '%', v);
            }
        }
    }

    return str;
}

if (getMode() == 'tablet' || getMode() == 'desktop' ) {
    scrollToBlock($('#covid-letter-block'), '', 150);
}

// when user select theme on main page after redirect to Booking templates page scroll to customizer and select chosen selected theme
let themesList = $('#templates .templates-list__item .btn--select-theme')
if( $(themesList).length ){
    themesList.on('click', function () {
        var selectedThemeName = $(this).data('theme-id');
        console.log('rewrite input val');
        localStorage.setItem('selectedTheme', selectedThemeName );
    })
}
var localStorageData = localStorage.getItem('selectedTheme');
if( $('.templates-items--section').length ){
    let themesList = $( '.templates-items--section .templates-items--list-item') // таких айтемів багато
    var selectedButton = themesList.filter(function(){
        return $(this).find('.anchor-link').data('theme-id') == localStorageData;
    }).find('.anchor-link');


    $( document ).ready(function() {
        console.log( "ready!" );
        setTimeout(function () {
            selectedButton.trigger('click');
            localStorage.removeItem('selectedTheme');
        }, 1500);
    });
}

function setWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + (ttl), // (ttl * 24 * 60 * 60 * 1000) - use for days, now in seconds
    }
    localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

$('#sb_ExitPopup').on('hidden.bs.modal', function () {
    //setWithExpiry('sm_popup_banner', 1, 6000);
    localStorage.setItem('sm_popup_banner', '1');
});

$(document).ready(function() {
    let $sb_vs_sm = $('.sb-vs-sm__main-section');

    $(document).bind('mouseleave', function (e) {
        let $sb_widget = $('.simplybook-widget-container');
        let $sb_widget__open = false;
        if ($($sb_widget).length != 0) {
            if ($($sb_widget).hasClass('active')) {
                $sb_widget__open = !$sb_widget__open;
            }
        }

        let $live_help = $('.i-embedded-form:visible');
        let $live_help__open = false;
        if ($($live_help).length) {
            $live_help__open = !$live_help__open;
        }

        let $all_modals = $('.modal:not(#sb_ExitPopup)');
        let all_modal_isOpen = false;
        if( $($all_modals).hasClass('in') ){
            all_modal_isOpen = !all_modal_isOpen;
        }

        //console.log( 'all modal = ', all_modal_isOpen );
        if (window.appLocale === 'en') {
            if ($sb_vs_sm.length == 0 && $sb_widget__open == false && $live_help__open == false && all_modal_isOpen == false) { // detect if we on not SB&SM page, enterprise widget is open, live chat is open, some popup is open
                if (e.pageY - $(window).scrollTop() <= 0.1) {
                    let hasPopup = $('#sb_ExitPopup');
                    if (hasPopup.length) {
                        //const status = getWithExpiry('sm_popup_banner', 1);
                        const status = localStorage.getItem('sm_popup_banner');
                        //console.log('status = ', status);
                        if (status != '1') {
                            $('#sb_ExitPopup').modal('show');
                        }
                    }
                }
            }
        }
    });

    //hide cookie banner for Igor's pages & Michail's pages
    if (window.location.href.indexOf("igor.d") > -1 || window.location.href.indexOf("memosh.dev18") > -1) {
        //$('.sb-important').fadeOut(); // $('.cookies').fadeOut();
    }
});
